$(document).ready(function(){

    $(".toggle-button").click(function(e){
        e.preventDefault();
        e.stopPropagation();
        $(this).toggleClass("open");
        $(this).next(".toggle-content").slideToggle("slow");
        $(this).closest(".row").next(".toggle-content").slideToggle("slow");  
        $(this).closest("h3").next(".toggle-content").slideToggle("slow"); 
        $(this).closest(".form-item").next(".toggle-content").slideToggle("slow");
        $(this).closest(".log-area").next(".toggle-content").slideToggle("slow");
    });

    $(".toggle-button.select").next(".toggle-content").click(function(e){
        e.stopPropagation();
    });

    $(document).on('click', function () {
        $(".toggle-button.select").next(".toggle-content").slideUp("slow");
    });

    $(".toggle-button-filter").click(function(){
        $(this).toggleClass("open");
        $(this).next(".toggle-content").slideToggle("slow");
    });

    //multiselect dropdowns
    var $togglecontent = $(".toggle-content-multiselect");
    $("#crop_iq_reports .toggle-button").click(function () {
        var $togglebutton = $(this).next(".toggle-content-multiselect").stop(true).slideToggle("slow");
        $togglecontent.not($togglebutton).filter(":visible").stop(true).slideUp();
    });

    $(".multiselect .toggle-button").click(function () {
        var $togglebutton = $(this).next(".toggle-content-multiselect").stop(true).slideToggle("slow");
        $togglecontent.not($togglebutton).filter(":visible").stop(true).slideUp();
    });

    $(".multiselect .block-color li").click(function () {
        $(".toggle-content-multiselect").slideUp("slow");  
    });

    $(".view-subsites").click(function(){
        $(this).toggleClass("open");
        $(this).closest(".original-site").next(".subsite-wrapper").slideToggle("slow");  
    });

    $( "#accordion" ).accordion({
        collapsible: true,
        header: "> div > h3",
        heightStyle: "content"
    })

    .sortable({
        axis: "y",
        handle: "h3",
        stop: function( event, ui ) {
            // IE doesn't register the blur when sorting
            // so trigger focusout handlers to remove .ui-state-focus
            ui.item.children( "h3" ).triggerHandler( "focusout" );

            // Refresh accordion to handle new order
            $( this ).accordion( "refresh" );
        }
    });

    $( "#accordion-weather-stations" ).accordion({
        collapsible: true,
        heightStyle: "content",
        active: false
    });

    $( "#accordion-soil-probes" ).accordion({
        collapsible: true,
        heightStyle: "content",
        active: false
    });

    $( ".soil-probe-toggles > div" ).accordion({
        collapsible: true,
        heightStyle: "content"
    });

    $( "#accordion-notsortable" ).accordion({
        collapsible: true,
        header: "> div > h3",
        heightStyle: "content"
    });

    $("input#shipping-address").click(function () {
        $(".shipping-address-fields").slideToggle("slow");
    });

    $("input#Override").click(function () {
        $(".industry-override").slideToggle("slow");
        $(".planting-fruit-info").toggleClass("inactive");
    });


    //crm filters 
    $(".btn-filter-toggle.open").click(function(){
        $(this).addClass("hide");
        $(".search-filters").addClass("opened");
    });

    $(".btn-filter-toggle.close").click(function(){
        $(".btn-filter-toggle.open").removeClass("hide");
        $(".search-filters").removeClass("opened");
    });

    //ellipsis list
    $("a.u-icon-ellipsis").click(function(){
        $(this).next(".ellipsis-option-list").slideToggle("slow");
    });

    //read more container
    $(".read-more-button").click(function(){
        $(".read-more-container").toggleClass("open");
    });
});
